import React from "react";
import './index.css'
import Home from './routes/Home'
import About from './routes/About'
import Project from './routes/Project'
import Contact from './routes/Contact'
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';



function App() {
  const location = useLocation();
  return (
    <>
    <AnimatePresence mode="wait">
      <Routes location={location}>
        <Route path="/" element={<Home/>} />
        <Route path="/project" element={<Project/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
